import { Component } from '@angular/core';

@Component({
  selector: 'app-p500',
  templateUrl: './p500.component.html',
  styleUrls: ['./p500.component.css']
})
export class P500Component {

}
