import { Component } from '@angular/core';

@Component({
  selector: 'app-p404',
  templateUrl: './p404.component.html',
  styleUrls: ['./p404.component.css']
})
export class P404Component {

}
