import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import * as Global from 'src/app/shared/globals';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  Global = Global;
  title = 'HRMS';
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
  ) { }
  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.loadStylesheet(event?.snapshot.params['lang']);
      }
    });
  }
  loadStylesheet(language: string): void {
    const existingLink = document.querySelector(`link[href*="styles-ar.css"]`);

    if (existingLink) {
      existingLink.remove();
    }

    if (language == 'ar') {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'stylesheet');
      this.renderer.setAttribute(link, 'type', 'text/css');
      this.renderer.setAttribute(link, 'href', `assets/styles/styles-${language}.css`
      );

      this.renderer.appendChild(document.head, link);
      return;
    }
  }
}
