import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { StrorageService } from '../services/strorage.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private storage:StrorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token =  this.storage.getToken()
    if(token){
      request = request.clone({
        setHeaders:{
          "x-access-token":token  
        }
      });
    }
    
    return next.handle(request)
    // .pipe(catchError((err:HttpErrorResponse) => {
      // if(err.status === 401) {
      //   console.log('Unauthorized access!')
      // }
      // else if(err.status === 404) {
      //   console.log('Page Not Found!')
      // }
      // return throwError(() => err)
    // }));
  }
}
