import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { StrorageService } from '../services/strorage.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeGuard {
  constructor(
    private storage: StrorageService,
    private router: Router,
    private langService: LanguageTranslateService,
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticated = this.storage.getToken();
    const lang = this.langService.getLangLocalStorage() ?? 'en';
    if (!isAuthenticated) {
      this.router.navigateByUrl(`/${lang}/login`);
      return false;
    } else {
      return true;
    }
  }

}
